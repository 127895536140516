


















































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import { WecomVideoServices } from '@/services/wecom-video-service';
import { Inject } from '@cds/common';
import UserGroupSelector from '../userGroupSelector.vue';

import { massSet } from '@/services/massage-service';
import { filter, map, toString } from 'lodash';

type ChannelForm = {
    [key: string]: any;
    sourceName: string;
    owner: string;
    agents: any[],
    groups: any[]
    groupIds: any[]
    customGroupIds: any[]
}

@Component({
    components: {
        UserGroupSelector
    }
})
export default class ChannelManagement extends Vue {
    [x: string]: any;
    public tableData: any[] = [];
    public noNumshow: Boolean = false;
    public noNum: number = 0;
    public totalNum: number = 0;
    public useNum: number = 0;
    public empIds: any[] = [];
    public emails: any[] = [];
    public visible: Boolean = false;
    public loading: Boolean = false;
    public isAdmin: Boolean = false;
    public agents: any[] = [];
    public form: any = {
        pageSize: 10,
        pageNo: 1,
        sourceName: '',
        owner: ''
    }
    public channelForm: ChannelForm = {
        sourceName: '',
        owner: '',
        agents: [],
        groups: [],
        groupIds: [],
        customGroupIds: []
    }
    public rules = {
        sourceName: [{ required: true, message: '请输入频道名称' }],
        owner: [{ required: true, message: '请输入管理员' }],
        // groups: [{ required: true, message: '请' }],
    };
    public create() {
        this.channelForm = {
            sourceName: '',
            owner: '',
            agents: [],
            groups: [],
            groupIds: [],
            customGroupIds: []
        }
        this.visible = true;
        if (this.$refs.channelForm) {
            (this.$refs.channelForm as ElForm).resetFields();
        }
    }
    @Inject(WecomVideoServices) public wecomVideoServices!: WecomVideoServices
    @Inject(massSet) public massSet!: massSet;

    public search() {
        this.form.pageNo = 1;
        this.form.pageSize = 10;
        // getTableData
        this.getDataList();
    }
    public created() {
        this.getDataList();
        this.checkIsAdmin();
        this.getOptions();
    }
    public checkIsAdmin() {
        this.wecomVideoServices.checkIsAdmin().then((res) => {
            this.isAdmin = !!res;
        })
    }
    public handleGroupSubmit({ groupIds, customGroupIds }) {
        console.log(groupIds);
        console.log(customGroupIds);
        this.channelForm.groupIds = groupIds;
        this.channelForm.customGroupIds = customGroupIds;
    }
    public userListStrWrite(e: any) {
        var strLength = [], Arr_ = [];
        this.noNumshow = false;
        if (this.channelForm.owner == '' || this.channelForm.owner == null || this.channelForm.owner == undefined) {
            this.totalNum = 0;
        } else {
            strLength = this.channelForm.owner.split(',');
            Arr_ = Array.from(new Set(strLength));
            this.channelForm.owner = Arr_.toString();
            this.totalNum = Arr_.length;
        }
    }
    public checkPersonInCreate() {
        this.checkPerson('create');
    }
    // 检测用户
    public checkPerson(status: 'create' | 'submiting' = 'create'): Promise<void> {
        var data_ = {};
        data_ = {
            "usersStr": this.channelForm.owner,
        }
        return new Promise(async (resolve, reject) => {
            let res: any = await this.massSet.persnSearch(data_);
            if (res) {
                this.noNum = res.unFinds.length;
                this.useNum = res.empIds.length + res.emails.length;
                this.totalNum = res.unFinds.length + res.empIds.length + res.emails.length;
                this.noNumshow = true;
                this.empIds = res.empIds;
                this.emails = res.emails;
                // this.form.userListStr = res.usersStr;
                console.log(status, 'status');
                if (res.unFinds.length > 0) {
                    if (status === 'create') {
                        this.$alert('用户：' + res.unFinds.toString() + ' 不存在', '错误用户', {
                            confirmButtonText: '确定'
                        });
                    } else {
                        this.$message(`用户：${res.unFinds.toString()} 不存在'`)
                    }
                    reject();
                } else {
                    if (status === 'create') {
                        this.$message({
                            type: 'info',
                            message: '用户检测完成'
                        });
                    }
                    resolve();
                }
            }
        })
    }
    //删除图文
    public async handleDelete(row: any): Promise<void> {
        let param = {
            id: row.id
        };
        try {
            await this.$confirm('确定要删除吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            await this.wecomVideoServices.deleteSource(param)
            this.getDataList()
            this.$message({
                message: '删除成功',
                type: 'success'
            });
        } catch (e) {
            console.log(e);
            this.$message({
                type: 'info',
                message: '已取消删除'
            });
        }
    }
    public handleEdit(row: any) {
        this.wecomVideoServices.getSouceDetail(row.id).then((res) => {
            if (res) {
                this.visible = true;
                const groups = map(res.groups, (group) => {
                    return group.groupType === 'custom' ? `custom_${group.groupId}` : `${group.groupId}`;
                })
                const customGroupIds = map(filter(res.groups, (item) => item.groupType === 'custom'), (item) => item.groupId);
                const groupIds = map(filter(res.groups, (item) => item.groupType === 'group'), (item) => item.groupId);
                this.channelForm = {
                    owner: res.owner,
                    sourceName: res.sourceName,
                    ...res,
                    agents: res.channelAgentIds,
                    groups,
                    customGroupIds,
                    groupIds
                }
                this.unauthGroups = map(filter(res.groups, (item) => !item.editable), (item) => {
                    const group = { ...item, disabled: true, label: item.groupName };
                    if (item.groupType === 'custom') {
                        group.key = `custom_${item.groupId}`;
                        group.isCustomer = 1;
                        group.label = `${item.groupName}（自定义分组）`
                    } else {
                        group.isCustomer = 0;
                        group.key = `${item.groupId}`;
                    }
                    return group
                })
                this.agents = [...this.agents];

            }
        })
    }
    public handleSizeChange(size: number) {
        this.form.pageSize = size;
        this.getDataList();
    }
    public getDataList() {
        this.loading = true;
        this.wecomVideoServices.getSourceList({ ...this.form }).then((res) => {
            console.log(res)
            this.form.total = res.total;
            this.tableData = res.records;
        }).finally(() => {
            this.loading = false;
        })
    }
    public getOptions() {
        this.wecomVideoServices.getAgentList().then(res => {
            this.agents = res;
        })
    }
    public save() {
        (this.$refs.channelForm as ElForm).validate((valide: Boolean) => {
            if (valide) {
                this.checkPerson('submiting').then(() => {
                    this.updateAndCreate()
                });
            }
        })
    }
    public updateAndCreate() {
        const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        })
        const params: any = {
            channelAgentIds: this.channelForm.agents,
            emails: this.emails,
            empIds: this.empIds,
            sourceName: this.channelForm.sourceName,
            groupIds: this.channelForm.groupIds,
            customGroupIds: this.channelForm.customGroupIds,
        }
        if (this.channelForm.id) {
            params.id = this.channelForm.id;
            this.wecomVideoServices.updateSource(params).then((res) => {
                if (res) {
                    this.$message({
                        type: 'success',
                        message: '频道已更新',
                    })
                    this.visible = false;
                    this.totalNum = 0;
                    this.useNum = 0;
                    this.noNumshow = false;
                    this.getDataList();
                }
            }).finally(() => {
                loading.close();
            })
        } else {
            this.wecomVideoServices.createSource(params).then((res) => {
                if (res) {
                    this.$message({
                        type: 'success',
                        message: '频道已创建',
                    })
                    this.visible = false;
                    this.totalNum = 0;
                    this.useNum = 0;
                    this.noNumshow = false;
                    this.getDataList();
                }
            }).finally(() => {
                loading.close();
            })
        }
    }
    public handleCurrentChange(current: number) {
        this.form.pageNo = current;
        this.getDataList();
    }
}
